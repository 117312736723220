import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { graphql, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { SplashScreen } from '../../components/misc';
import { DispatchContext } from '../../lib/providers';
import { APP_CLEAR_USER_DATA } from '../../lib/events/app/types';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function SignOut() {
  const dispatch = useContext(DispatchContext);
  const [signOutMessage, seSignOutMessage] = useState('👋');

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.error('An error occured: ', error);
      seSignOutMessage('An error occured signing out');
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await signOut();
        dispatch({ type: APP_CLEAR_USER_DATA });
        await navigate('/');
      } catch (error) {
        dispatch({ type: APP_CLEAR_USER_DATA });
      }
    })();
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amwell Private Practice | Sign Out</title>
      </Helmet>
      <SplashScreen label={signOutMessage} />
    </>
  );
}
